.sale-token-summary {
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #818A98;
  }
  &__rate-update {
    margin-top: 16px;
    padding: 20px;
    background: #E9F3FE;
    border-radius: 10px;
    &-cpay {
      border: 1px solid #2A92FF;
    };
    &-nfg {
      border: 1px solid #5283FC;
    };
    &-title {
      display: block;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #3B4857;
    }
    &-subtitle {
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #818A98;
    }
    &-button {
      margin-top: 20px;
      width: 170px;
      height: 42px;
    }
  }
  &__section {
    margin-top: 20px;
  }
  &__section-title {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #818A98;
  }
  &__section-value {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #3B4857;
    word-break: break-all;
  }
  &__timer {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #5F6E80;
    svg {
      margin-right: 5px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;
    margin: 50px -10px 0;
    @media screen and (max-width: 531px) {
      justify-content: center;
      margin: 30px -10px 0;
    }
  }
  &__button {
    min-width: 196px;
    height: 50px;
    border-radius: 6px;
    margin: 0 10px;
    @media screen and (max-width: 531px) {
      margin: 20px 10px 0;
    }
  }
}