.modal-delete-merc {
  .ant-modal-header {
    display: none;
  }
  .ant-modal-body {
   padding: 0px !important;
  }
  .ant-modal-close {
    display: none;
  }
  .delete-modal-merc {
    padding: 40px 107px !important;
    @media screen and (max-width: 768px) {
      padding: 15px;
    }

    &-mobile {
      padding: 40px !important;
    }
  }
  &__buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   // gap: 5%;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      padding: 0px 15px;
      & * {
        max-width: 100%;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        & > button {
          width: 100%;
        }
      }
    }
  }
}
