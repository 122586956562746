.version-overlay {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  z-index: 1000;
  padding: 5px 10px;
  font-size: 14px;
  border-top-left-radius: 5px;
  font-weight: 600;
  cursor: pointer;

  &-cpay {
    right: 0;
  };

  &-nfg {
    left: 0;
  }

  &.checkout {
    opacity: 0;
  }
}
