.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin: 0 5px;
  }

  .active {
     a {
       color: #1890ff;
     }
  }

  .disabled {
    color: #bfbfbf;
    cursor: not-allowed;
  }

  a {
    text-decoration: none;
    color: #000000;
  }

  .previous, .pagination .next {
    padding: 0 16px;
  }
}