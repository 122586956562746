.wrapper {
  width: 100%;
  max-width: 1376px;
  margin: 0 auto;

  @media screen and (max-width: 1625px) {
    max-width: 1034px;
  }
  
  @media screen and (max-width: 1097px) {
    max-width: 692px;
  }
  
  @media screen and (max-width: 711px) {
    max-width: 350px;
  }
}