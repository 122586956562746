.newFooter {
  margin: 0px -35px;
  grid-column-gap: 45px;
  grid-row-gap: 45px;
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 60px 100px;
  display: flex;

  &__container {
    width: 100%;
    max-width: 1240px;
    min-width: 360px;
    grid-column-gap: 45px;
    grid-row-gap: 45px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;

    @media screen and (max-width: 500px) {
      padding: 0px 15px;
    }
    @media screen and (max-width: 360px) {
      padding: 0px 25px;
    }
  }

  &__block {
    width: 100%;
    min-width: 240px;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;

    &-header {
      width: 100%;
      min-width: 240px;
      grid-column-gap: 32px;
      grid-row-gap: 32px;
      flex-flow: wrap;
      justify-content: space-between;
      align-items: center;
      display: flex;
      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: start;
      }

      &-list {
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        flex-direction: row;
        align-items: center;
        display: flex;
        @media screen and (max-width: 600px) {
          flex-direction: column;
          align-items: start;
        }

        a {
          color: #192f5d;
          font-family: Inter, sans-serif;
          font-size: 16px;
          font-weight: 600;
          text-decoration: none;
          text-align: center;
        }
      }
    }

    &-buttonBlock {
      width: 100%;
      min-width: 240px;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      text-align: center;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      display: flex;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }

      &-storeButtons {
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      &-social-links {
        display: flex;
        position: relative;
        grid-column-gap: 16px;
      }
    }

    &-bottomTextBlock {
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      display: flex;

      &-mainText {
        max-width: 700px;
        color: #192F5D;
        text-align: left;
        justify-content: center;
        align-items: center;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        display: flex;
      }

      &-secondText {
        color: #3B4857;
        text-align: left;
        flex: none;
        justify-content: flex-start;
        align-items: center;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        display: flex;
      }
    }
  }
  .footer-links-social {
    margin-bottom: 0px;
    gap: 24px;
    a {
      margin-bottom: 0px;
      margin-left: 0px;
    }
    a:hover {
      opacity: .8;
      outline: 0;
    }
  }
}