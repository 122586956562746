.modal__private-key {
  .modal {
    &__text {
      margin-bottom: 20px;
      cursor: pointer;
    }
    &__warning {
      text-align: center;
    }
    &__button-create {
      width: 100%;
    }
  }
}

.security-pass {
  padding: 20px 80px 60px;
    @media screen and (max-width: 768px) {
      padding: 15px;
    }
}

.text__wallet {
  text-align: center;
  margin-bottom: 15px;
}

.wrapper {
  display: flex;
  justify-content: center;

  div:first-child {
    margin-right: 5px;
    font-weight: bold;
  }
}