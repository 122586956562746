.ant-layout-sider {
  .sider {
    min-height: 611px;
    height: calc(100vh - 64px);
    position: relative;
    .sider__nav-title {
      transition: visible 0.3s ease, opacity 0.3s ease;
    }
    &__regular {
      .sider__nav-title {
        visibility: visible;
        opacity: 1;
      }
    }
    &__collapsed {
      .sider__nav-title {
        visibility: hidden;
        opacity: 0;
      }
    }
    &__nav-item {
      padding-left: 0;
      padding-right: 0;
      margin-top: 0;
      height: 50px;
      overflow: visible;
      &:first-child {
        margin-top: 50px;
      }
      &:not(:last-child) {
        margin-bottom: 44px;
      }
      &_float,
      &_float.ant-menu-item-selected.ant-menu-item-selected {
        width: 100%;
        position: absolute;
        bottom: 37px;
        left: 50%;
        transform: translateX(-50%);
      }
      &.ant-menu-item-selected {
        position: relative;
        background: transparent;
      }
      &.ant-menu-item-active {
        background: none;
      }
    }
    &__nav-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-icon {
        display: block;
      }
      &-icon_active {
        display: none;
      }
      &_active,
      &:hover {
        .sider__nav-link-icon {
          display: none;
        }
        .sider__nav-link-icon_active {
          display: block;
        }
        &:after {
          content: "";
          display: block;
          width: 2px;
          height: 32px;
          position: absolute;
          right: 0;
          top: -5px;
          border-radius: 5px 0px 0px 5px;
        }
      }
      &-cpay {
        color: #273443;
        &:hover {
          color: #2988EF;

          &:after {
            background: #2988EF;
          }
        }
      };
      &-nfg {
        color: #5283FC;
        &:hover {
          color: #5283FC;
          & > svg {
            path,
            circle {
              stroke: #0E0D0D;
            }
          }

          &:after {
            background: #5283FC;
          }
        }
       
      }

      &-finvaro {
        color: #000000;
        &:hover {
          color: #8097B2;

          &:after {
            background: #8097B2;
          }
        }
      };

      &-clarnium {
        color: #5A627E;
        &:hover {
          color: #2571FF;

          &:after {
            background: #2571FF;
          }
        }
      };
    }
  }
}

.sider__nav-tooltip_regular {
  display: none !important;
}
