.not-found {
  font-family: 'Open Sans';
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: url("../../img/main-img/not-found-404.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  &__box{
    padding: 30px 40px 95px;
    width: 820px;
    background: #FFFFFF;
    box-shadow: 2px 4px 16px rgba(115, 129, 143, 0.16);
    border-radius: 10px;
  }
  &__header {
    margin-bottom: 20px;

    &-clarnium {
      img {
        height: 24px;
      }
    }
  }
  &__main-block {
    width: 310px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span{
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 150px;
      line-height: 204px;
      text-align: center;
      color: #E0E6ED;
    }
    &-text{
      margin-bottom: 24px;

      h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        color: #3B4857;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        color: #818A98;
      }
    }

  }
  &__button {
    display: flex;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    color: #FFFFFF;
    padding: 11px 89px;
    border-radius: 4px;
    &-cpay {
      background: #2988EF;
    };
    &-nfg {
      background: #5283FC;
    };
    &-finvaro {
      width: 200px;
    }
    &-clarnium {
      background: #2571FF;
    };
  }
  a:hover {
    color: #FFFFFF;
  }
}
