.page-header {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  &--create {
    flex-direction: row;
    width: 100%;
    margin-bottom: 15px;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }

  & > p {
    margin: 0;
    margin-right: 20px;
    @media screen and (max-width: 768px) {
      margin: 0;
      margin-bottom: 20px;
    }
  }

  &__button-create.button {
    display: block;
    min-width: 239px;
    background: transparent;
    border-radius: 4px;
    transition: all 0.3s ease;
    cursor: pointer;
    @media screen and (max-width: 1023px) {
      margin-left: 0;
      width: 80%;
    }
    &:hover {
      border-color: #40a9ff;
      color: #fff;
    }
    @media screen and (max-width: 525px) {
      width: 65%
        }

        @media screen and (max-width: 425px) {
          width: 50%
            }
  }
  &__button-create-icon {
    margin-top: -3px;
    margin-right: 4px;
  }

  &__back {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    gap: 5px;

    &-cpay {
      span {
        color: #2988EF;
      }
    }

    &-nfg {
      span {
        color: #5283FC;
      }
    }

    &-finvaro {
      span {
        color: #000;
      }
    }

    &-clarnium {
      span {
        color: #2571FF;
      }
    }
  }

  &__button-create-cpay.button {
    color: #2988EF;
    border: solid 1px #2988EF;

    &:hover {
      border-color: #40a9ff;
    }
  }

  &__button-create-nfg.button {
    color: #5283FC;
    border: solid 1px #5283FC;

    &:hover {
      border-color: #014BD2;
      color: #fff;
      text-align: center;
      img {
        display: none;
      }
    }
      &:hover:not(:disabled) {
        background: #5283FC;
    }
  }

  &__button-create-clarnium.button {
    color: #2571FF;
    border: solid 1px #2571FF;

    &:hover {
      border-color: #438FFF;
    }
  }

  &__button-create-finvaro.button {
    color: #000000;
    border: solid 1px #000000;

    &:hover {
      border-color:#8097B2;
      color: #fff;
      text-align: center;
      img {
        display: none;
      }
    }
    &:hover:not(:disabled) {
      background: #8097B2;
    }
  }

}
