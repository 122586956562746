.wallets-page {
  .wallet {
    padding: 10px 0;
    font-size: 12px;
    &:not(:last-of-type) {
      border-bottom: solid 1px rgba(211, 220, 230, 0.6);
    }
    &__icon {
      cursor: pointer;
    }
    &__expand {
      display: flex;
      align-items: center;
      &_opened svg {
        transform: rotateZ(180deg);
        path {
          fill: #2988EF;
        }
      }
      svg {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
    &__icon {
      img {
        width: 30px;
        height: 30px;
      }
    }
    &__tooltip {
      display: flex;

      .copy-wrapper {
        width:  100% !important;
        max-width: max-content;
      }
    }
    &__mark {
      color: #0770dc;
      &:hover {
        cursor: pointer;
      }
    }
    &__address {
      &-cpay {
        color: #0770dc;
      };
      &-nfg {
        color: #5283FC;
      }
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 5px;
      white-space: nowrap;
      &-copy {
        display: inline;
        cursor: pointer;
      }
    }
    &__currency-amount span {
      display: block;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
    &__usd-amount span {
      display: block;
    }
    &__key span {
      display: block;
    }
    &__status {
      display: flex;
      align-items: center;
      &:before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        margin-right: 4px;
      }
      &_default {
        color: #23B852;
        &:before {
          background: #23B852;
        }
      }
      &_secondary {
        color: #fc4b4e;
        &:before {
          background: #fc4b4e;
        }
      }
    }
    &__options {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
    &__secondary {
      .wallet__address {
        color: #8492a5;
      }
    }
    &__min-replenish {
      display: inline-block;
      margin-top: 10px;
      padding: 6px 10px;
      background: #E9F8EE;
      border-radius: 4px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: #3B4857;
    }
  }
  .wallet-tokens {
    height: 0;
    overflow: hidden;
    &_opened {
      height: auto;
    }
    &_grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, 358px);
      gap: 16px;
      margin-top: 16px;
    }
    &__item {
      margin-top: 20px;
      border-radius: 6px;
      &--top {
        display: flex;
        align-items: center;
      }
      &--bottom {
        font-size: 14px;
        margin-top: 12px;
        &-bold {
          font-weight: 600;
        }
      }
    }
    &__name {
      margin-left: 4px;

      &--currency {
        padding: 2px 4px;
        background-color: #fff;
        font-size: 8px;
        border-radius: 20px;
      }
      &--title {
        font-size: 14px;
        font-weight: 600;
        margin-top: 2px;
      }
    }
    &__icon {
      width: 30px;
      height: 30px;
    }
    &__address {
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 5px;
    }
  } 
}
