.merchant-item {
  display: flex;
  align-items: stretch;
  padding: 20px 30px;
  background: #ffffff;
  border-radius: 10px;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 768px) {
    padding: 8px 10px;
    justify-content: space-between;
    border-top: 1px solid #e0e6ed;
    border-bottom: 1px solid #e0e6ed;
    border-right: 1px solid #e0e6ed;
    border-radius: 6px;
  }
  & > div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
  &__more {
    max-width: 50px !important;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 17px;
      cursor: pointer;
    }
    &-dropdown {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #d3dce6;

      & > * {
        padding: 15px;
        &:hover {
          background: #2989ef1a;
          cursor: pointer;
        }
        &:first-child {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }
        &:last-child {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
      &-edit {
        color: #818a98;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
      }
      &-delete {
        color: #e85b48;
        &:hover {
          color: #e85b48;
        }
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
      }
    }
    @media screen and (max-width: 768px) {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  &__name {
    flex-direction: column;
    align-items: flex-start !important;
    & > h2 {
      margin: 0;
      margin-bottom: 2px;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }
    & > p {
      margin: 0;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #8492a5;
    }
  }
  &__wallets {
    flex-direction: column;
    &-button {
      padding: 7px 17px;
      display: block;
      max-width: 174px;
      width: 100%;
      min-height: 21px;
      text-align: center;
      border-radius: 4px;
      text-align: center;
      color: #fff;
      font-weight: 600;
      font-size: 12px;
      line-height: 21px;
      white-space: nowrap;
      &-cpay {
        background: #2988EF;
      }
      &-nfg {
        background: #5283FC;
      }
      &-finvaro {
        background: #BBD7FF;
        color: #000000;

        &:hover {
          color: #ffffff;
          background: #8097B2 !important;
        }
      }
      &-clarnium {
        background: #2571FF;
      }
      &:hover {
        color: #ffffff;
        background: #2988EFe6;
      }
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      @media screen and (max-width: 768px) {
        padding: 5px 10px;
      }
    }
  }
  &__type {
    &-cpay {
      color: #2988EF;
    }
    &-nfg {
      color: #5283FC;
    }
    &-finvaro {
      color: #000000;
    }
    &-clarnium {
      color: #2571FF;
    }
  }
  &__networks-dropdown {
    width: 100%;
    background: #fff;
    padding: 20px 0;
    border: 1px solid #d3dce6;
    margin-top: -9px;
    border-radius: 0 0 6px 6px;
    border-top: 0;
    text-align: center;
    .ant-radio {
      display: none;
    }
    .ant-checkbox-group,
    .ant-radio-group {
      display: flex;
      flex-direction: column;
      max-height: 340px;
      overflow-x: hidden;
      .ant-radio-wrapper {
        margin-right: 0;
        &:hover {
          background: #f2f8ff;
        }
      }
      .ant-checkbox-group-item:not(:first-of-type),
      .ant-radio-wrapper:not(:first-of-type) {
        margin-top: 10px;
      }
    }
  }
  &__networks-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 107px;
    height: 29px;
    padding-left: 15px;
    background: transparent;
    font-size: 12px;
    font-weight: 600;
    border-radius: 6px 6px 0 0;
    cursor: pointer;
    border: solid 1px transparent;
    border-bottom: none;
    transition: border-color 0s linear;
    transition-delay: 0.2s;

    &--type {
      margin-top: 25px;
    }

    &-cpay {
      color: #273443;
    };
    &-nfg {
      color: #000000;
    };
    &_mainnet {
      color: #7b61ff;

      &-clarnium {
        color: #9747FF;
      }
    }
    &_testnet {
      color: #f3ba2f;

      &-clarnium {
        color: #F7931A;
      }
    }
    &_hover {
      transition-delay: 0.1s;
      background: inherit;
      border-color: #d9d9d9;
    }
    .anticon {
      margin-top: 2px;
      margin-left: 16px;
    }
    &:disabled {
      display: none;
      background: red;
    }
  }
}

.testnet {
  border-left: 13px solid #f3ba2f;
  @media screen and (max-width: 768px) {
    border-left: 6px solid #f3ba2f;
  }
}
.mainnet {
  border-left: 13px solid #7b61ff;
  @media screen and (max-width: 768px) {
    border-left: 6px solid #7b61ff;
  }
}
