.sale-token-form {
  &__rate-update {
    margin-top: 16px;
    margin-bottom: 20px;
    padding: 20px;
    background: #E9F3FE;
    border-radius: 10px;
    &-cpay {
      border: 1px solid #2A92FF;
    };
    &-nfg {
      border: 1px solid #5283FC;
    };
    &-title {
      display: block;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #3B4857;
    }
    &-subtitle {
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #818A98;
    }
    &-button {
      margin-top: 20px;
      width: 170px;
      height: 42px;
    }
  }
  &__input-wrapper {
    &:not(:first-of-type) {
      margin-top: 16px;
    }
  }
  &__input-label {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #3B4857;
  }
  &__input {
    position: relative;
    display: flex;
    width: 100%;
    border: 1px solid #D3DCE6;
    border-radius: 6px;
    input {
      all: unset;
      width: calc(100% - 127px);
      box-sizing: border-box;
      padding: 13px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #3B4857;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    &-text {
      .sale-token-form__input-error {
        padding-left: 0;
      }
      input {
        width: 100%;
      }
    } 
  }
  &__input-right {
    width: 170px;
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      width: 1px;
      height: 32px;
      background: #D3DCE6;
    }
    :global {
      .ant-select-arrow {
        width: auto;
        height: auto;
        top: 20px;
        right: 4px;
        svg {
          display: block;
          path {
            stroke: #9B9FB8;
          }
        }
      }
      .ant-select-open .ant-select-arrow svg {
        transform: scaleY(-1);
      }
    }
  }
  &__select {
    width: 100%;
    height: 100%;
    :global(.ant-select-selector) {
      height: 100% !important;
      border: none !important;
      box-shadow: none !important;
      border-radius: 10px !important;
      padding: 0 11px 0 8px !important;
    }
    :global(.ant-select-selection-item) {
      display: block;
      line-height: 48px !important;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #3B4857;
    }
  }
  &__select-option {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #3B4857;
    &-icon {
      margin-bottom: 3px;
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
  }
  &__input-currency {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 11px 0 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #3B4857;
    &-icon {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
  }
  &__input-info {
    position: relative;
    padding: 22px 0 0 30px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 15px;
      width: 1px;
      height: calc(100% - 8px);;
      background: #D3DCE6;
    }
    &-child {
      position: relative;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #818A98;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: -19px;
        transform: translateY(-50%);
        width: 9px;
        height: 9px;
        background: #D3DCE6;
        border-radius: 50%;
      }
    }
  }
  &__submit {
    &-cpay {
      background: #2A92FF;
    };
    &-nfg {
      background: #5283FC;
    };
    all: unset;
    box-sizing: border-box;
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    padding: 10px 20px;
    border-radius: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #FFFFFF;
    cursor: pointer;
    transition: opacity .3s ease;
    &:hover {
      opacity: .85;
    }
    &:disabled {
      background: #D3DCE6;
    }
  }
  &__input-error {
    position: absolute;
    top: 100%;
    color: #E85B48;
    padding-left: 30px;
    font-size: 12px;
  }
  &__timer {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: calc(100% + 5px);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #5F6E80;
    svg {
      margin-right: 5px;
    }
  }
  &__warning {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 20px 15px;
    background: #FDF1D5;
    border: 1px solid #F0B90B;
    border-radius: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #3B4857;
    svg {
      margin-right: 12px;
    }
  }
}