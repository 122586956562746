.merchant-data-form {
  margin-top: 0;
  .form__title {
    margin-top: 40px;
  }
  &__api-key {
    display: flex;
    align-items: center;
    word-break: break-all;
    b {
      margin-right: 5px;
    }
  }

  .form__button {

    &.es {
      height: 45px;
    }
  }
  .copy-icon {
    display: block;
    cursor: pointer;
    margin-left: 5px;
  }

  &__error {
    color: red;
  }
}

.security {
  cursor: pointer;

  &__add {
    display: inline-block;
    margin-left: 6px;
    color: #2a92ff;
    font-size: 12px;
    font-weight: 600;
  }

  &__col {
    display: flex;
    align-items: end;
    position: relative;

    &--close {
      position: absolute;
      z-index: 1;
      right: 3px;
      top: 27px;
      width: 15px;
      height: 15px;
      background: #d4d7d9;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &-row {
        top: 5px;
      }

      svg {
        width: 12px !important;
        height: 12px !important;
      }
    }
  }
}

.security-modal {
  padding: 40px 30px;

  button {
    margin: 0 auto;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  .ant-form-item-label {
    display: flex;
  }
}
