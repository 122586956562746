.admin-history {
  margin-top: 35px;

  &__row {
    display: flex;
    align-items: center;
    gap: 20px;
    color: #3B4857;

    &--data {
      padding: 22px 0;
      font-size: 14px;
      font-weight: 600;

      &--item {
        color: #2a92ff;
      }
    }

    &--item {
      display: block;
      overflow-wrap: break-word;

      &:nth-of-type(1) {
        width: 20%;
      }

      &:nth-of-type(2) {
        width: 60%;
        white-space: wrap;
      }

      &:nth-of-type(3) {
        width: 20%;
      }
    }
  }
}

.login-pagination {
  margin-top: 68px;
  & .ant-col-2 {
    min-width: 100%;
  }
  @media screen and (max-width: 768px) {
    margin-top: 30px;
    & .ant-col-2 {
      min-width: 100%;
      margin-bottom: 20px;
    }
  }
  &__wrapper {
    display: flex;
    justify-content: center;
    .ant-pagination.mini .ant-pagination-item {
      min-width: 10px;
      margin: 0 10px;
      a {
        padding: 0;
      }
    }
    .ant-pagination-item-active {
      border: none;
      // border-bottom: solid 1px #2988EF;
      border-radius: 0;
      min-width: 10px;
    }
    &-cpay  {
      .ant-pagination-item-active {
        border: none;
        border-bottom: solid 1px #2988EF;
        border-radius: 0;
        min-width: 10px;
      }
    }

    &-nfg {
      .ant-pagination-item-active {
        border: none;
        border-bottom: solid 1px #5283FC;
        border-radius: 0;
        min-width: 10px;
      }
    }
  }
}