.hljs {
  display: block;
  overflow-x: auto;
  background: transparent;
  color: #4dd162;
}

.hljs-name {
  color: #E85B48;
}

.hljs-string {
  color: #4dd162;
}

.hljs-tag {
  color: white;
}
.hljs-attr {
  color: #efab48;
}

.hljs-selector-class {
  color: orange;
}
