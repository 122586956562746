.wallet-filters {
  padding-bottom: 15px;
  & > div {
    display: flex;
    flex: 1;
    align-items: center;
  }
  &__title {
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
  }
  &__search {
    .ant-input-search-icon:before {
      display: none;
    }
    @media screen and (max-width: 1023px) {
      margin-top: 10px;
    }
  }
  &__search-field {
    width: 100%;
    height: 36px;
    position: relative;
    .ant-input-affix-wrapper-disabled {
      background: transparent;
    }
    .ant-input-suffix {
      margin-right: 32px;
      margin-top: 1px;
      .anticon-search {
        margin-left: 0;
      }
    }
    .ant-input-group-addon {
      width: auto;
      position: absolute;
      top: 2px;
      right: 1px;
      background: #fff;
      padding: 0;
      border: none;
    }
  }
  &__date .ant-picker-range {
    height: 36px;
    width: 100%;
    &.ant-picker-disabled {
      background: transparent;
    }
  }
  &__amount-range {
    .ant-input-affix-wrapper-disabled,
    .ant-input-disabled {
      background: transparent;
    }
    .ant-input-affix-wrapper {
      padding-right: 0;
      border-right: none;
      width: 50%;
      height: 36px;
      &.ant-input-affix-wrapper-focused {
        box-shadow: none;
      }
      .ant-input {
        margin-top: 1px;
      }
      .ant-input-suffix {
        margin-left: 0;
      }
    }
    .transaction-filters__to-usd {
      border-left: none;
      width: 50%;
      height: 36px;
      &:focus {
        box-shadow: none;
      }
    }
    &:hover {
      .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled),
      .transaction-filters__to-usd:not(.ant-input-disabled) {
        border-color: #40a9ff;
      }
    }
    &-icon {
      height: 17px;
    }
  }
  &__reset-button {
    width: 32px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover,
    &:focus {
      background: none;
      box-shadow: none;
    }
    &:after {
      display: none;
    }
  }
  &__currencies-button {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 36px;
    background: transparent;
    border: 1px solid #d9d9d9;
    font-size: 12px;
    font-weight: normal;
    border-radius: 2px;
    &-cpay {
      color: #273443;
    };
    &-nfg {
      color: #000000;
    };
    cursor: pointer;
    transition: border-color 0.3s ease;
    @media screen and (max-width: 1023px) {
      margin-top: 10px;
    }
    &:hover {
      background: inherit;
      border-color: #40a9ff;
    }
    .anticon {
      margin-top: 2px;
    }
    &:disabled {
      display: none;
      background: red;
    }
  }
  &__currencies-zero {
    margin-top: 10px;
  }
  &__currencies-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    &-all {
      color: #2A92FF;
      cursor: pointer;
    }
    &-clear {
      color: #818A98;
      cursor: pointer;
    }
  }
  &__currencies-dropdown {
    width: 100%;
    background: #fff;
    padding: 16px;
    border: 1px solid #d3dce6;
    border-radius: 3px;
    .ant-checkbox-group,
    .ant-radio-group {
      display: flex;
      flex-direction: column;
      max-height: 340px;
      overflow-x: hidden;
      .ant-checkbox-group-item:not(:first-of-type),
      .ant-radio-wrapper:not(:first-of-type) {
        margin-top: 12px;
      }
    }
    .dropdown-divider {
      height: 1px;
      margin: 12px -17px 12px -17px;
      background: #d3dce6;
    }
    &_apply {
      width: 120px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      border-radius: 4px;
      text-align: center;
      margin: 20px auto 0;
      cursor: pointer;
      &-cpay {
        background: #2988EF;
      }
      &-nfg {
        background: #5283FC;
      }
    }
  }
}