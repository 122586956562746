.login-info {
  width: 100%;
  height: 100%;
  &__title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    max-width: 240px;
    &-cpay {
      color: #273443;
    };
    &-nfg {
      color: #000000;
    };
    &-clarnium {
      color: #0F162B;
    };
  }
  &__text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    max-width: 240px;
    &-cpay {
      color: #273443;
    };
    &-nfg {
      color: #000000;
    };
  }
  &__highlight {
    display: block;
    margin-bottom: 10px;
    &-cpay {
      color: #2988EF;
    }

    &-nfg {
      color: #5283FC;
    }
  }
  &__thesis {
    display: block;
    position: relative;
    padding-left: 10px;

    &-clarnium {
      color: #5A627E;
    }
    &:not(:first-of-type) {
      margin-top: 5px;
    }
    &:before {
      content: "-";
      display: block;
      position: absolute;
      left: 0;
    }
  }
}
