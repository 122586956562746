.custom-instruction {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__switcher {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;

        label {
            margin-right: 0px;
        }
    }

    ol {
        list-style: none;
        margin: 0;
        padding-left: 20px;
        color: #3B4857;
    }

    &__list {
        list-style-type: none;
        margin-top: -10px;
        padding-left: 20px;
        gap: 10px;
        color: #3B4857;

        li {
            margin-bottom: 10px;
        }
    }

    &__details-box{
        margin-top: -30px;
        padding: 10px;
        border-radius: 10px;
        background-color: #F9FAFD;
        gap: 6px;

        p {
            margin-bottom: 6px;
        };

        p:last-child {
            margin: 0px;
        }

    }

    &__final-box{
        padding: 10px;
        background-color: #E9F8EE;
        border-radius: 10px;

        > p {
            margin-bottom: 0px;
            color: black;
            padding: 0px 10px;
        }
    }
}