.modal-delete-merc {
  .ant-modal-header {
    display: none;
  }
  .ant-modal-close {
    display: none;
  }
  .delete-modal-wallet {
    padding: 40px 107px !important;
    @media screen and (max-width: 768px) {
      padding: 15px;
    }

    &-mobile {
      padding: 40px !important;
    }
  }
}
