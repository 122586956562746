.autoswap {
  margin-top: 20px;

  &__hr {
    display: block;
    margin: 20px 0;
  }
}

.swap-exchange__reverse--swap {
  cursor: pointer;
  svg {
    height: 42px;
  }
}

@media (max-width: 1023px) {
  .swap-exchange__reverse--swap {
    margin-top: 15px;
    margin-left: 5px;
  }
}

.merchant-data-form__website {
  width: 100%;
  .ant-select-selector {
    height: 42px !important;
    cursor: pointer !important;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
  .ant-select-selection-search {
    padding-left: 25px;
    input {
      height: 39px !important;
    }
  } 
}

.data__label {
  margin-top: 12px;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}

.component__wrapper {
  .form__button {
    margin-top: 0;
  }
}

.swap-amount-field__listWrapper {
  display: block !important;
  margin-top: 26px;

  &__block {
    display: block;
    margin-bottom: 20px;

    &__inner {
      display: flex;
    }
  }
}

.swap-amount-field__list {
  &__crop {
    width: 311px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}