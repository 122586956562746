// Display ant.design's date range input dropdown as one calandar instead of two
.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:nth-child(2) {
  display: none;
}

.ant-picker-panel-container,
.ant-picker-footer {
  width: 280px !important;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}