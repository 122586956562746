.checkout-payment {
  width: 100vw;
  height: 100vh;
  position: relative;
  transition: background .1s ease;
  @media screen and (max-width: 800px) {
    width: 100%;
    height: 100%;
  }
  background: #f1f3f6;
  padding: 60px 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  overflow: auto;
  @media screen and (max-width: 600px) {
    padding: 43px 12px 30px;
  }
  &__custom-logo {
    position: absolute;
    top: 30px;
    left: 60px;
    img {
      display: block;
      max-width: 240px;
      max-height: 40px;
      width: 100%;
      object-fit: contain;
    }
    @media screen and (max-width: 768px) {
      position: static;
      margin-bottom: 30px;
    }
  }
  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .langSelector {
    margin-top: 0;
    position: absolute;
    top: 43px;
    right: 45px;
    height: 30px;

    @media screen and (max-width: 786px) {
      top: 20px;
      right: 20px;
    }

    &__label {
      display: none;
    }

    &__dropdown {
      top: 30px;
      
      @media screen and (max-width: 1359px) {
        left: -91%;
      }

      @media screen and (min-width: 1360px) {
        left: 0;
      }
    }
  }

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    margin-bottom: 30px;
  }
  &__description {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 27px;
    max-width: 384px;
    margin-bottom: 40px;
  }
  &__info-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    .checkout-payment__container {
      margin-left: 20px;
    }
    @media screen and (max-width: 800px) {
      .checkout-payment__container {
        margin-top: 20px;
        margin-left: 0;
      }
      flex-direction: column;
    }
  }
  &__info {
    margin-bottom: 16px;
  }
  &__info,
  &__info-big {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 466px;
    padding: 18px 30px;
    background: #ffffff;
    border-radius: 19px;
    &-logo {
      flex: 60px;
    }
    &-wrapper {
      flex: 100%;
      text-align: left;
      margin-left: 20px;
      overflow-wrap: anywhere;
    }
    &-title {
      font-size: 18px;
      color: #000000;
      line-height: 25px;
    }
    &-description {
      font-size: 16px;
      color: #3b4857;
      line-height: 22px;
    }
  }
  &__info-big {
    flex-direction: column;
    &-logo {
      @media screen and (mix-width: 801px) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      & > img {
        max-width: 100%;
        max-height: 315px;
      }
    }
    &-wrapper {
      flex: 0;
      margin-left: 0;
      margin-bottom: 15px;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 466px;
    background: #ffffff;
    padding: 15px 20px 15px 20px;
    border-radius: 19px;
    box-shadow: 2px 4px 16px rgba(115, 129, 143, 0.16);
    @media screen and (max-width: 800px) {
      padding: 20px 10px;
    }
    @media screen and (max-width: 600px) {
      padding: 20px 10px;
    }
    @media screen and (min-width: 600px) {
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
  &__timer {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #5f6e80;
    margin-top: 20px;
  }
  &__button {
    padding: 8px 58px;
    overflow: unset;
    margin-top: 30px;
  }
  &__footer {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    margin-top: 20px;
    & > a {
      color: inherit;
      span {
        font-weight: bold;
      }
    }
  }

  &__loading-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.checkout-payment-loading {
  width: 100%;
  max-width: 466px;
  background: #ffffff;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 2px 4px 16px rgba(115, 129, 143, 0.16);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
