.sale-token-payment {
  padding: 0 40px;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
  &__amount,
  &__address {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #818A98;
  }
  &__address {
    margin-top: 20px;
  }
  &__field {
    position: relative;
    border: 1px solid #D3DCE6;
    border-radius: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #8491A5;
    padding: 15px 45px 15px 15px;
    margin-top: 10px;
    word-break: break-all;
    @media screen and (max-width: 768px) {
      font-size: 12px;
      padding: 15px 35px 15px 10px;
    }
    &-copy {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      cursor: pointer;
      @media screen and (max-width: 768px) {
        right: 10px;
      }
      svg {
        display: block;
        fill: #2988EF;
      }
    }
  }
  &__min-trans-info {
    display: flex;
    align-items: center;
    background: #F5F5FB;
    border-radius: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #2988EF;
    padding: 10px 20px;
    margin-top: 20px;
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
    svg {
      margin-right: 10px;
    }
    &-cpay {
      color: #2988EF;
    }
    &-nfg {
        color: #5283FC;
    }
  }
  &__get, &__recepient {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #3B4857;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
    span {
      color: #818A98;
    }
  }
  &__get {
    margin-top: 20px;
  }
  &__recepient {
    margin-top: 8px;
    word-break: break-all;
  }
  &__qrcode {
    display: block;
    margin: 43px auto 0;
  }
}