.support {
  width: 100%;
  background: #ebf3fc;
  padding: 73px 38px 38px;

  &-clarnium {
    background: #F5F6FF;
  }
  &_title {
    font-weight: 600;
    font-size: 20px;
    &-cpay {
        color: #273443;
    };
    &-nfg {
        color: #0E0D0D;
    };
    &-finvaro {
      color: #000000;
    };
    &-clarnium {
      color: #5A627E;
    };
  }
  label {
    display: block;
    font-weight: 600;
    font-size: 12px;
    //color: #000000;
    margin-top: 20px;
  }
  &-cpay {
    label {
      color: #273443;
    }
  };
  &-nfg {
    label {
      color: #0E0D0D;
    }
  };
  &-finvaro {
    label {
      color: #000000;
    }
  };
  &-clarnium {
    label {
      color: #5A627E;
    }
  };
  &_issue {
    display: block;
    width: 100%;
    height: 36px;
    border-radius: 3px;
    font-weight: normal;
    font-size: 12px;
    color: #3b4857;
    .ant-select-selector {
      height: 36px !important;
      border: none !important;
      .ant-select-selection-item {
        line-height: 36px;
      }
    }
  }
  &_email {
    border: none;
    height: 36px;
    color: #3b4857;
    .ant-input {
      font-size: 12px;
    }
  }
  &_description {
    border: none;
    min-height: 90px !important;
    color: #3b4857;
    margin-bottom: 20px;
    font-size: 12px;
    padding: 10px 16px;
  }
  &_tip {
    font-size: 12px;
    font-weight: 400;
    color: #3b4857;
  }
  &_captcha {
    margin-top: 20px;
  }
  &_submit {
    width: 296px;
    height: 34px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    margin-top: 20px;
  }
  &_submit-cpay {
    background: #2988EF;
  }
  &_submit-nfg {
    background: #5283FC;
  }
  &_submit-finvaro {
    background: #BBD7FF;
  }
  &_submit-clarnium {
    background: #2571FF;
  }
}
