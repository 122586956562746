.modal-merchants {
  padding: 30px 105px 60px 105px;
  @media screen and (max-width: 768px) {
    padding: 15px;
  }
  .form-create-merchant {
    &__comission-info {
      display: flex;
      width: 100%;
      padding: 6px 14px;
      margin-top: 20px;
      background: #f9fafd;
      border-radius: 4px;
      font-size: 12px;
      color: #5f6e80;
    }
    &__fee-docs {
      display: block;
      font-weight: bold;
      font-size: 12px;
      color: #238af6;
      margin-top: 16px;
      margin-left: 14px;
      margin-bottom: 20px;

      &-finvaro {
        color: #000000;
      }

      &-clarnium {
        color: #2571FF;
      }
    }
  }

  &__popover {
    margin-left: 5px;
    &-icon_active {
      display: none;
    }
    &.ant-popover-open {
      .password-strength__popover-icon {
        display: none;
      }
      .password-strength__popover-icon_active {
        display: inline;
      }
    }
  }
}

.popover__wrapper {
  max-width: 300px;
}

