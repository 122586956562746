.sale-token-progress {
  display: flex;
  justify-content: space-between;
  margin-top: 26px;

  &__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #818A98;
    text-align: center;
    &_done {
      .sale-token-progress__step-icon {
        background: #D3DCE6;
      }
    }
    &_active {
      &-cpay {
        color: #2A92FF;
        .sale-token-progress__step-icon {
          border-color: #2A92FF;
          background: #2A92FF;
        }
      };
      &-nfg {
        color: #5283FC;
        .sale-token-progress__step-icon {
          border-color: #5283FC;
          background: #5283FC;
        }
      };
    }
  }

  &__step-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: solid 1px #D3DCE6;
  }

  &__step-name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    @media screen and (max-width: 768px) {
      font-size: 10px;
    }
  }
}