.merchant-security {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    h2 {
      display: block;
      font-weight: 600;
      font-size: 14px;
      margin: 0 !important;
    }

    > div {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }
      p {
        margin: 0 !important;
        font-size: 14px;
        color: #3b4857;
      }
    }

    &-cpay {
      h2 {
        color: #273443;
      }
    }
    &-nfg {
      h2 {
        color: #000000;
      }
    }
  }

  &__info {
    margin: 0 !important;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    gap: 30px;

    button {
      padding: 1px 15px;
    }
  }
}
