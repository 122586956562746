.checkout-client-data {
  width: 100%;
  height: 100%;
  padding: 30px;
  background: #FFFFFF;
  border-radius: 19px;
  &__title {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    text-align: left;
    color: #000000;
  }
  &__email {
    margin-top: 20px;
  }
  &__name, &__email {
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #E2E2DF;
    box-sizing: border-box;
    border-radius: 10px;
  }
  &__submit {
    width: 144px;
    height: 34px;
    left: 800px;
    top: 623px;
    color: #fff;
    border-radius: 4px;
    margin: 0 0 0 auto;
    &-cpay {
      background: #2988EF;
    }
    &-nfg {
      background: #5283FC;
    }
  }
}