.autosigns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #E0E6ED;
  padding-top: 20px;
  margin-top: 20px;

  &-finvaro {
    .ant-switch-checked {
      background-color: #8097B2;
    }
  }

  &-clarnium {
    .ant-switch-checked {
      background-color: #2571FF;
    }
  }

  &__title {
    color: #3B4857;
    font-size: 14px;
    font-weight: 600;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
}
