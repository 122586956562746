@import "../src/highlight.scss";

body {
  background: #f1f3f6;
}

#root {
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  @media screen and (max-width: 1023px) {
    height: 100%;
    max-height: unset;
    // overflow: auto;

  }
}

.text-normal {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

.flex-auto {
  flex: auto;
}
.block-for-component {
  flex: 1 0 auto;
}

.component {
  padding: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 2px 4px 16px rgba(115, 129, 143, 0.16);
  &_form-padding {
    padding-bottom: 40px;
  }
  &__title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-weight: 600;
    font-size: 16px;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__button {
    width: 135px !important;
  }
}

.strong {
  font-weight: 600;
}

.ant-layout {
  // min-width: 1366px;
  height: 100vh;
  overflow: hidden;
  &-header {
    padding: 0;
    box-shadow: 2px 4px 16px rgba(115, 129, 143, 0.16);
  }
  &-header,
  &-sider {
    background: #ffffff;
  }
  &-content {
    padding: 20px 32px 0px;
    // height: 100vh;
    display: flex;
    flex-direction: column;
    > div {
      padding-bottom: 63px;
    }
  }
}

.form {
  margin-top: 30px;
  &__item {
    margin-top: 12px;
    margin-bottom: 0;
  }
  &__input {
    height: 36px;
    border: 1px solid #d3dce6;
    border-radius: 3px;
  }
  &__input.form__input-phone {
    border: 1px solid #d3dce6;
    padding: 4px 11px 4px 0px;
    background: #fff;
    input {
      border-radius: 2px;
      border: none;
    }
    .flag-dropdown {
      background-color: #fff;
      border: none;
    }
    .form-control {
      width: 100%;
      height: 100%;
    }
  }
  &__prefix-input {
    .ant-input-group-addon {
      background-color: #fff;
    }
    .ant-select-selector {
      margin: 0 !important;
      padding: 2px 0 !important;
      width: 100% !important;
      min-width: unset !important;
    }
    .ant-select {
      max-width: 100px;
    }
    .ant-select-selection-item {
      padding-right: 30px !important;
      padding-left: 10px !important;
    }
    .ant-input {
      height: 36px;
    }
  }
  &__textarea {
    border-radius: 3px;
  }
  &__title {
    display: block;
    font-weight: 600;
    font-size: 14px;
    &-cpay {
      color: #273443;
    };
    &-nfg {
        color: #0E0D0D;
    };
    &-finvaro {
      color: #000000;
    };
    &-clarnium {
      color: #5A627E;
    };
    &:not(:nth-of-type(1)) {
      margin-top: 20px;
    }
  }
  &__subtitle {
    display: block;
    font-size: 12px;
    color: #5f6e80;

    &-clarnium {
      color: #9AA2C3;
    }
    &:not(:nth-of-type(2)) {
      margin-top: 12px;
    }
  }
  &__select .ant-select-selector {
    min-height: 36px;
    max-height: 36px;
    border-radius: 3px !important;
    align-items: center;
  }
  &__button {
    width: 100%;
    font-weight: 600;
    border-radius: 4px;
    margin-top: 20px;

    &-cpay {
      background: #2988EF;
    };

    &-nfg {
      background: #5283FC;

      &:hover {
        background: #014BD2;
      }
    }

    &-finvaro {
      background: #BBD7FF;
      color: #000000 !important;
    }

    &-clarnium {
      background: #2571FF;
    }
  }
  .ant-form-item-label {
    padding-bottom: 0;
    label {
      font-weight: 600;
      font-size: 12px;
      color: #000000;
    }
  }
}

.login-form {
  @media screen and (max-width: 768px) {
    height: auto;
    min-height: 100%;
    width: 100%;
    padding: 100px 15px 30px 15px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    & > .ant-form-item {
      width: 100%;
    }
  }
  height: 100%;
  padding: 94px 38px 60px;
  background: rgb(235, 243, 252);

  &-clarnium {
    background: #F5F6FF;

    @media screen and (max-width: 768px) {
      background: #ffffff;
    }
  }
  &__title {
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    margin-bottom: 20px;

    &-clarnium {
      color: #0F162B;
    }
  }
  &__label {
    margin-bottom: 2px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #000000;

    &-clarnium {
      color: #0F162B;
    }
  }
  &__tip {
    font-size: 12px;
    color: #777;
  }
  &__link {
    font-weight: 400;
    font-size: 12px;
    margin-top: 3px;
    cursor: pointer;

    &-cpay {
      color: #2988EF;
    };

    &-nfg {
      color: #5283FC;
    }

    &-finvaro {
      color: #000000;

      &:hover {
        color: #000000;
      }
    }

    &-clarnium {
      color: #2571FF;
    };
  }
  &__label_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  &__input,
  &__button {
    width: 298px;
    height: 36px;
    border: none;
    border-radius: 4px;
    box-shadow: none;
  }
  &__input {
    @media screen and (max-width: 768px) {
      width: 100%;
      border: 1px solid #d3dce6;
    }
  }
  &__radio {
    margin-bottom: 20px;
    img {
      margin-right: 8px;
    }
    &-cpay {
      .ant-radio-wrapper {
        display: block;
        color: #2988EF;
        font-weight: 600;
        .ant-radio-inner {
          border-color: #2988EF;
          &:after {
            background-color: #0770dc;
          }
        }
        &.ant-radio-wrapper-checked {
          color: #0770dc;
          .ant-radio-inner {
            border-color: #0770dc;
          }
        }
        &:first-of-type {
          margin-bottom: 10px;
        }
      }
    }

    &-nfg {
      .ant-radio-wrapper {
        display: block;
        color: #5283FC;
        font-weight: 600;
        .ant-radio-inner {
          border-color: #5283FC;
          &:after {
            background-color: #5283FC;
          }
        }
        &.ant-radio-wrapper-checked {
          color: #5283FC;
          .ant-radio-inner {
            border-color: #5283FC;
          }
        }
        &:first-of-type {
          margin-bottom: 10px;
        }
      }
    }

    &-finvaro {
      .ant-radio-wrapper {
        display: block;
        color: #BBD7FF;
        font-weight: 600;
        .ant-radio-inner {
          border-color: #BBD7FF;
          &:after {
            background-color: #0770dc;
          }
        }
        &.ant-radio-wrapper-checked {
          color: #0770dc;
          .ant-radio-inner {
            border-color: #0770dc;
          }
        }
        &:first-of-type {
          margin-bottom: 10px;
        }
      }
    }

    &-clarnium {
      .ant-radio-wrapper {
        display: block;
        color: #2571FF;
        font-weight: 600;
        .ant-radio-inner {
          border-color: #2571FF;
          &:after {
            background-color: #0770dc;
          }
        }
        &.ant-radio-wrapper-checked {
          color: #0770dc;
          .ant-radio-inner {
            border-color: #0770dc;
          }
        }
        &:first-of-type {
          margin-bottom: 10px;
        }
      }
    }

  }
  &__input-phone.form__input {
    width: 100%;
    border: none;
    .form-control, .flag-dropdown {
      border: none;
    }
    @media screen and (max-width: 768px) {
      border: 1px solid #d3dce6;
    }
  }
  &__input svg {
    color: #5f6e80;
  }
  &__input .ant-input {
    font-size: 12px;
  }
  &__button-wrapper {
    width: 167px;
    margin-top: 10px;
  }
  &__button {
    position: relative;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    img {
      width: 18px;
      margin-right: 10px;
      margin-left: -10px;
    }

    &-cpay {
      background: #2988EF;
    }

    &-nfg {
      background: #5283FC;

      &:hover {
        background: #014BD2;
      }
    }

    &-finvaro {
      background: #BBD7FF;
      color: #000;

      &:hover {
        background-color: #8097B2;
        color: #fff;
      }
    }

    &-clarnium {
      background: #2571FF;
    }
  }
  &__text {
    width: 100%;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    &_center {
      text-align: center;
    }
    &_space-between {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 20px;
      &-cpay {
        a {
          color: #1890ff;
        }
      }

      &-nfg {
        a {
          color: #5283FC;
        }
      }

      &-finvaro {
        a {
          color: #000000;
        }
      }

      &-clarnium {
        a {
          color: #2571FF;
        }
      }
    }
    &-cpay {
      a {
        color: #1890ff;
      }
    }

    &-nfg {
      a {
        color: #5283FC;
      }
    }
  }
  &__reset-link {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-top: 10px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }

    &-cpay {
      border: solid 1px #2988EF;
      color: #2988EF;
    }

    &-nfg {
      border: solid 1px #5283FC;
      color: #5283FC;
    }

    &-finvaro {
      color: #000000;

      &:hover {
        color: #000000;
      }
    }

    &-clarnium {
      border: solid 1px #2571FF;
      color: #2571FF;
    }
  }
  &__phrase {
    display: flex;
    align-items: center;
     // width: 298px;
    min-height: 36px;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    background: #fff;
    margin-bottom: 24px;
    padding: 2px 11px;
    @media screen and (max-width: 768px) {
      width: 100%;
      border: 1px solid #d3dce6;
    }
    .react-tagsinput-input,
    .react-tagsinput-tag {
      margin-top: 2px;
      margin-bottom: 2px;
    }
    .react-tagsinput-input {
      font-size: 14px;
      width: 100%;
      &::placeholder {
        color: #bfbfbf;
      }
    }

    &.add-wallet__modal {
     border: 1px solid #d9d9d9;
    }

    &-cpay {
      .react-tagsinput-tag {
        background: #F1F3F6;
        border: none;
        border-radius: 4px;
        color: #5F6E80;
        padding: 4px 12px;
        .react-tagsinput-remove {
          color: #5F6E80;
        }
      }
    }

    &-nfg {
      .react-tagsinput-tag {
        background: #5283FC;
        border: none;
        color: #fff;
        .react-tagsinput-remove {
          color: #fff;
        }
      }
    }

    &-finvaro {
      .react-tagsinput-tag {
        background: #BBD7FF;
        border: none;
        color: #000;
        .react-tagsinput-remove {
          color: #000;
        }
      }
    }

    &-clarnium {
      .react-tagsinput-tag {
        background: #2571FF;
        border: none;
        border-radius: 4px;
        color: #FFF2F2;
        padding: 4px 12px;
        .react-tagsinput-remove {
          color: #FFF2F2;
        }
      }
    }
  }
  &__captcha {
    margin-bottom: 20px;
  }
  &__buttons-wrap {
    display: flex;
    margin-bottom: 20px;
    width: 100%;
    .login-form__button {
      flex: 1;
      height: 34px;
    }
  }
  &__back-button {
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
  }
}

.border-line {
  height: 1px;
  margin: 26px -20px 0;
  background: #b0bbcb;
  opacity: 0.3;
}

.two-factor-qr {
  display: block;
  margin: 20px auto;
}

.react-tagsinput-remove:before {
  content: " ×" !important;
}

.recharts-surface {
  overflow: visible !important;
}

.recharts-wrapper {
  padding-left: 20px;
}

.custom-tooltip {
  width: 150px;
  height: 150px;
  background-color: white;
}

.recharts-label {
  font-weight: 600;
}

.link {
  &-cpay {
    color: #2988EF;
  };
  &-nfg {
    color: #5283FC;
  }
  &-finvaro {
    color: #000000;
  }
  &-clarnium {
    color: #2571FF;
  };
}

.wizzard__dot--active {
  width: 25px !important;
  border-radius: 10px !important;
}

// temporary (I hope) solution to hide old version of walletconnect //
.web3modal-provider-wrapper:last-of-type {
  display: none;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");
