.home-page, .transaction-page {
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  flex: 1 0 auto;
  gap: 20px;
  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
  }
  &__left {
    width: 100%;
    max-width: 420px;
  }
  &__right {
    width: 100%;
    max-width: 760px;
  }
  @media screen and (max-width: 1023px) {
    gap: unset;
    &__left, &__right {
      max-width: unset;
    }
    &__right {
      margin-top: 20px;
    }
  }
}
