.sale-token-done {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 769px) {
    padding: 50px 60px;
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #4DD162;
    margin-top: 10px;

    &-clarnium {
      color: #19B892;
    }
  }
  &__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #3B4857;
    margin-top: 30px;
  }
  &__summary {
    width: 100%;
    margin-top: 38px;
    border-left: dashed 2px #D3DCE6;
    padding-left: 12px;
  }
  &__summary-item {
    &:not(:first-of-type) {
      margin-top: 30px;
    }
  }
  &__summary-item-title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #818A98;
  }
  &__summary-item-value {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #3B4857;
    word-break: break-all;
    svg {
      margin-left: 5px;
      cursor: pointer;
    }
  }
  &__summary-item-scan-link {
    color: #3B4857;
  }
  &__button {
    margin-top: 30px;
    padding: 20px;
  }
}