.modal {
  top: 176px;
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-header {
    padding: 20px 24px;
    background: rgba(0, 135, 252, 0.1);
  }
  &-clarnium {
    .ant-modal-header {
      background: #F5F6FF !important;
    }
  }
  .ant-modal-body {
    padding: 0px !important;
  }
  .ant-modal-close {
    height: 63px;
  }
  &__text {
    text-align: center;
    margin-bottom: 32px;
  }
  &__tip {
    &-cpay {
      color: #273443;
    };
     &-nfg {
      color: #000000;
     };
    font-size: 12px;
  }
  &__warning {
    margin-top: 20px;
    font-size: 10px;
    &_red {
      display: inline-block;
      margin-right: 2px;
      color: #E85B48;
    }
  }
  &__buttons-wrapper {
    margin-top: 40px;
  }
  &__button {
    display: block;
    width: 144px;
    height: 34px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    box-shadow: none;
    &-cancel {
      margin-left: auto;
      color: #E85B48;
      background: transparent;
      border: 1px solid #E85B48;
      transition: background 0.3s ease, color 0.3s ease;
      &:hover:not(:disabled) {
        background: #E85B48;
        border: 1px solid #E85B48;
        color: #fff;
      }
    }
    &-create {
      &-cpay {
        background: #2A92FF;
        border-color: #2A92FF;
      }
  
      &-nfg {
        background: #5283FC;
        border-color: #5283FC;
      }

      &-finvaro {
        background: #BBD7FF !important;
        border-color: #BBD7FF !important;
        color: #000000 !important;

        &:hover {
          background-color: #8097B2;
          border-color: #8097B2;
          color: #ffffff;
        }
      }

      &-clarnium {
        background: #2571FF;
        border-color: #2571FF;
      }
    }
  }
  &__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.modal-form {
  &.ant-form label {
    font-size: 12px;
  }
  &__remove-autoswap {
    border-radius: 12px;
  }
  &__label {
    margin-bottom: 20px;
    .ant-form-item-label {
      font-size: 12px;
      font-weight: 600;
      padding-bottom: 0;
      label {
        font-size: 12px;
        height: auto;
      }
    }
  }
  &__input {
    width: 100%;
    height: 36px;
    border-radius: 3px;
    font-size: 12px;
  }
  &__checkbox {
    display: flex;
    flex-direction: column;
    label {
      margin-top: 12px;
    }
  }
  &__title {
    color: #2988EF;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }
  &__subtitle {
    margin-top: 12px;
    font-size: 12px;
    text-align: center;
  }
  &__btnWrapper {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  &__description {
    font-size: 12px;
    color: #5f6e80;

    &-clarnium {
      color: #9AA2C3;
    }
  }
  &__chars-limit {
    font-size: 12px;
    color: #8491A5;
    position: absolute;
    top: 10px;
    right: 15px;
  }
}
