.react-multi-carousel-dot-list {
  bottom: -28px !important;
}

.wizzard {
  margin-bottom: 52px;
  position: relative;
  height: auto;
  font-family: "Open Sans", sans-serif;

  &__card {
    width: 100%;
    min-height: 80px;
    height: 100%;
    padding: 19px 20px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e6ed;
    gap: 5px;
    cursor: pointer;
    position: relative;

    &--hide {
      width: 20px;
      height: 20px;
      background: #f1f3f6;
      border-radius: 50%;
      position: absolute;
      top: 1px;
      right: 1px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: flex-start;
      width: 100%;
      gap: 10px;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__title {
    color: #141414;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin: 0;
    text-align: start;
  }

  &__description {
    color: #5f6e80;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 7px;
    text-align: start;
    max-width: 386px;
  }

  &__content {
    border-radius: 12px;
    background: #f1f3f6;
    padding: 20px;
    display: flex;
    gap: 15px;

    &--links {
      justify-content: space-between;
      padding: 20px 27px 20px 20px;

      .wizzard__item-header {
        align-items: flex-start;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    }
  }

  &__button {
    border-radius: 8px;
    border: 1px solid #2988ef;
    background: #2988ef;
    color: white;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    padding: 11px 28px;
    white-space: nowrap;
    width: 169px;

    &--outline {
      border: 1px solid #2988ef;
      background-color: transparent;
      color: #2988ef;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 100%;
    }
  }

  &__item {
    display: flex;
    gap: 12px;

    &--links {
      max-width: 210px;

      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
    }
  }

  &__item-index {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    border-radius: 6px;
    background: #2988ef;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: auto;
  }

  &__item-header {
    align-items: center;
    gap: 6px;
    // justify-content: space-between;
  }

  &__item-content {
  }

  &__item-title {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    text-align: start;
  }

  &__item-description {
    color: #979797;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: start;
  }

  &__item-link {
    color: #2a92ff;
  }

  &__dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #d3dce6;
    margin: 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 0;
    border: none;
    cursor: pointer;

    &--active {
      background: #2988ef;
    }
  }
}
