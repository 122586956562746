.modal-settings {
  height: 100% !important;

  &__body {
    padding: 10px 24px;
  }
}

.autosign {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &__download {
    cursor: pointer;
  }
}
