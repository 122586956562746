.profile-block {
  margin-top: 30px;

  .ant-checkbox-wrapper {
    margin-right: 10px;
  }

  .form__button {
    &.es {
      height: 43px;
    }
  }
}

.profile {
  &__title {
    display: block;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 15px;
    &-cpay {
      color: #273443;
    };
    &-nfg {
      color: #000000;
    };
    &:not(:nth-of-type(1)) {
      margin-top: 20px;
    }
    &_enabled {
      color: #23B852;
    }
    &_disabled {
      color: #E85B48;
    }
  }
  &__logout {
    width: 160px;
    margin-left: 20px;
    background: transparent;
    border-radius: 4px;
    &-cpay {
      color: #2988EF;
      border: 1px solid #2988EF;
    }
    &-nfg {
      color: #5283FC;
      border: 1px solid #5283FC;
      &:hover {
        border-color: #40a9ff;
        background: #5283FC;
      }
    }
    &-clarnium {
      color: #2571FF;
      border: 1px solid #2571FF;
      &:hover {
        border-color: #438FFF;
        background: #438FFF;
      }
    }
    &:hover {
      border-color: #40a9ff;
      color: #fff;
    }
  }
  &__subtitle {
    display: block;
    font-size: 12px;
    color: #5f6e80;
    &-clarnium {
      color: #9AA2C3;
    }
    &:not(:nth-of-type(2)) {
      margin-top: 12px;
    }
  }
  &__button {
    width: 100%;
    font-weight: 600;
    border-radius: 4px;
    margin-top: 20px;

    &-wrapper {
      display: flex;
      align-items: center;
    }
  }

  &-col {
    margin-left: auto;
  }
}
