.subscriptions {
  &__title {
    margin-top: 20px;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border-bottom: 1px solid #F2F8FF;
  }
}

.activities {
  color: #818A98;
  font-size: 14px;
  font-weight: 600;

  &__center {
    overflow: hidden;
    display: block;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 25px 5px;
    border-right: 1px solid rgba(129, 138, 152, 0.3);

    &--hash {
      display: flex;
      justify-content: center;
    }

    &--act {
      overflow: hidden;
      display: flex;
      justify-content: center;
      padding: 25px 5px;
      border-right: 1px solid rgba(129, 138, 152, 0.3);
    }

    &:last-child {
      border-right: none;
    }

    &--active {
      color: #23B852;
    }

    &--deactive {
      color: red;
    }
  }

  &__titleCenter {
    padding: 12px 0;
    text-align: center;
    display: flex;
    justify-content: center;
    border-right: 1px solid rgba(129, 138, 152, 0.3);

    &:last-child {
      border-right: none;
    }
  }

  &__rows {
    &:nth-child(odd) {
      background-color: #F9FAFC;
    }

    &--padding {
      padding: 25px 5px;
      display: block;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--padd {
      padding: 0 5px;
      display: block;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--paddingTitle {
      padding-right: 10px;
    }
    &--paddingType {
      padding-right: 20px;
    }
  }

  &__hash {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      margin-right: 5px;
    }

    &--icons {
      display: flex;
      justify-content: center;
      margin-left: 15px;
    }
  }

  &__wrapper {
    border: 1px solid rgba(129, 138, 152, 0.3);
    border-radius: 8px;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}