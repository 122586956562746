.sale-token-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  transition: background .1s ease;
  @media screen and (max-width: 768px) {
    padding: 40px 10px;
  }
  &__custom-logo {
    position: absolute;
    top: 30px;
    left: 60px;
    img {
      display: block;
      max-width: 240px;
      max-height: 40px;
      width: 100%;
      object-fit: contain;
    }
    @media screen and (max-width: 768px) {
      position: static;
      margin-bottom: 30px;
    }
  }
  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #000000;
  }
  &__subtitle {
    display: block;
    max-width: 400px;
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #757575;
  }
  &__payment-data {
    display: flex;
    align-items: center;
    max-width: 616px;
    width: 100%;
    margin-top: 50px;
    padding: 20px 30px;
    background: #FFFFFF;
    border-radius: 19px;
    @media screen and (max-width: 768px) {
      padding: 15px;
    }
  }
  &__payment-data-image {
    display: block;
    margin-right: 30px;
    max-width: 80px;
    max-height: 80px;
  }
  &__payment-data-title {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
  }
  &__payment-data-description {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3B4857;
    margin-top: 5px;
  }
  &__children-wrapper {
    max-width: 616px;
    width: 100%;
    margin-top: 37px;
    padding: 30px 40px;
    background: #FFFFFF;
    border-radius: 19px;
    @media screen and (max-width: 768px) {
      padding: 30px 15px;
    }
  }
}