.merchant-settings {
  margin-bottom: 20px;
}

.merchant-settings-form {
  &__button {
    width: 100%;
    font-weight: 600;
    border-radius: 4px;
    margin-top: 30px;
  }
  &__text {
    font-size: 12px;
    color: #5f6e80;

    &-clarnium {
      color: #9AA2C3;
    }
  }
  &__comission .ant-input-number {
    width: 100%;
  }
  &__comission {
    margin-bottom: 0;
  }
  &__comission-info {
    display: flex;
    width: 100%;
    padding: 6px 14px;
    margin-top: 20px;
    background: #F9FAFD;
    border-radius: 4px;
    font-size: 12px;
    color: #5F6E80;

    &.individual {
      background-color: transparent;
      margin-top: -20px;
    }
  }
  &__fee-docs {
    display: block;
    font-weight: bold;
    font-size: 12px;
    color: #238AF6;
    margin-top: 16px;
    margin-left: 14px;

    &-finvaro {
      color: #000000;
    }

    &-clarnium {
      color: #2571FF;
    }
  }
  &__payment-methods .ant-checkbox-wrapper-checked,
  &__commission-pays-cpay .ant-radio-wrapper-checked {
    color: #2988EF;
  }
  &__commission-pays-nfg .ant-radio-wrapper-checked {
    color: #5283FC;
  }
  .merchant-settings-form__commission-pays-info__popover {
    margin-left: 5px;
    &-icon_active {
      display: none;
    }
    &.ant-popover-open {
      .password-strength__popover-icon {
        display: none;
      }
      .password-strength__popover-icon_active {
        display: inline;
      }
    }
  }
  // &__payment-checkbox-group {
  //   label {
  //     display: block;
  //     &:not(:first-of-type) {
  //       margin-top: 6px;
  //     }
  //   }
  // }
}

.popover__wrapper {
  max-width: 300px;
}
