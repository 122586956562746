.main-layout {
  height: 100%;
  &__container {
    display: flex;
    height: calc(100% - 60px);
  }
  &__content-wrap {
    position: relative;
    display: flex;
    flex: 1;
    padding: 20px 32px;
    height: 100%;
    overflow: auto;
    padding-bottom: 0px;
    overflow-x: hidden;
    @media screen and (max-width: 767px) {
      padding: 20px 10px;
    }

    &--close {
      width: 20px;
      height: 20px;
      background: #d4d7d9;
      border-radius: 50%;
      position: absolute;
      top: 12px;
      right: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      
      &:hover {
        cursor: pointer;
      }

      @media screen and (max-width: 767px) {
        top: 11px;
        right: 5px;
      }

      @media screen and (min-width: 1475px) {
        top: 11px;
        left: 50%;
        transform: translateX(587px);
      }

      &-sider {
        @media screen and (min-width: 1266px) {
          top: 11px;
          left: 50%;
          transform: translateX(587px);
        }
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }
  &__content {
    width: 100%;
    // max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    &-cpay {
      justify-content: space-between
    }
    gap: 25px;
    .ant-row {
      flex: 1 0 auto;
    }

    .swap-history-filters__label,
    .swap-offers-filters__label {
      &.ant-row {
        flex: none;
      }
    }
  }
  &__box-shadow {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    background: #000000;
    opacity: 0.4;
    z-index: 9;
    // transition: all 0.3s ease-in-out;
    &-opened {
      width: 100%;
    }
  }
}

.warning {
  color: #e85b48;
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
}
